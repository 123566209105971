



































































































































import ViewModel from '@/views/company/Brand.ts';
export default ViewModel;
